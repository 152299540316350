@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans/OpenSans-Regular.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open Sans Regular';
    src: url('OpenSans/OpenSans-Regular.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('Roboto/Roboto-Regular.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto Regular';
    src: url('Roboto/Roboto-Regular.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}
