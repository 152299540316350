
@import "global_base.scss";

.main-menu, .main-menu ion-header ion-toolbar, .main-menu-content{
    background-color: var(--ion-color-light);
    --background: var(--ion-color-light);
}


.main-menu ion-list.list-md, .main-menu ion-list.list-md .item{
    background-color: var(--ion-color-light);
    --background: var(--ion-color-light);
    background: var(--ion-color-light);
  }
  .main-menu ion-list.list-md .item.active, 
  .main-menu ion-list.list-md .item.active .icon,
  .main-menu ion-list.list-md .item.active .label  {
    background-color:var(--ion-color-primary);
    --background: var(--ion-color-primary);
    color: var(--ion-color-light);
  }
  .main-menu ion-header ion-toolbar, ion-menu ion-header ion-toolbar{
    --background: var(--ion-color-light);
    border-radius: 0px 0px 0px 0px !important;
  }


ion-menu ion-content,
ion-menu ion-list,
ion-menu ion-list ion-item {
  --background: var(--ion-color-light);
  line-height: 20px;
}

 .main-menu{ 
  .item {
        .icon {
            color: var(--ion-color-primary);
        }
    }
 }
 .main-menu-content {
  
  
    .item {
       
        .icon {
           
            color: var(--ion-color-primary);
        }
        .label {
           
            color: var(--ion-color-primary);
           
        }
    }
  
  
  }

.login-page .wrapper-content ion-col.left{
    background-image: url(assets/custom/background.png);

} 
.login-image-home{
    margin-top: 40px;
    margin-bottom: 80px;
}

.login-image-long{
    margin-top: 150px;
  width: 60%;
}
.btn-link{
    color: var(--ion-color-primary);
}
.plt-mobile .logo-menu img{
   // width: 110px;
   // padding: 20px;
}
.logo-menu-open{
    //width: 100px;
    margin-left: auto;
    margin-right: auto;
}
logo-menu-collapsed{
  padding: 5px;
}

.title-content{
  padding: 10px;
  background-color: var(--ion-color-light);
  font-size: 110%;
  font-weight: bold;
}
app-my-account table td{
  text-align: center;
}

.FilesIDs .mat-form-field-underline {
  display: none;
}
