/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'theme/custom/custom.scss';
@import "~bootstrap/dist/css/bootstrap.css";
@import "assets/fonts/_fonts.scss";
//start cutom
@import "assets/fonts/custom/_fonts.scss";

//end custom

ion-menu.minimized {
  //--min-width:25px;
  min-width: 60px !important;
  //--max-width:25px;
  max-width: 60px !important;
}

ion-menu.maximized {
  //--min-width:25px;
  min-width: 270px !important;
  //--max-width:25px;
  max-width: 20% !important;
}

ion-menu ion-icon {
  margin-right: 0px;
}

ion-menu ion-content,
ion-menu ion-list,
ion-menu ion-list ion-item {
  --background: var(--ion-color-primary);
  line-height: 20px;
}
//$purple-color: #330052;


ion-menu ion-item ion-icon {
  //color: $purple-color;
  line-height: 20px;
}

ion-menu ion-item ion-label {
  margin-left: 15px;
}

ion-menu ion-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

ion-menu .menu-logo {
 
  height: 55px;
 
  
}


$closed: 55px;
$opened: 250px;

.menu-hidden {
    display: none;
}

.router-menu-hidden {
    left: 0 !important;
}

.main-menu {
    position: absolute;
    height: 100%;
    z-index: 2;
    background: var(--ion-color-primary);
    width: $closed;
    transition: width 0.5s;
}

.main-menu-opened {
    width: $opened;
}

.menu-closed {
    left: 55px;
    transition: left 0.5s;
}

.menu-showed {
    left: $opened;
}

.shadow-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: background 0.5s;
    z-index: 1;
}

@media (max-width: 600px) {
    .main-menu {
        position: absolute;
        height: calc(100% - 56px);
        z-index: 2;
        background: #fff;
        width: 0;
        transition: width 0.5s;
    }
    
    .main-menu-opened {
        width: 80%;
    }

    .menu-closed {
        left: 0;
        transition: left 0.5s;
    }
            
    .shadow-menu-opened {
        display: block;
        background: #00000050;
    }
    
}


.logo-menu {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: width 0.5s, margin-left 0.5s
}
.logo-menu-expanded{
  margin-top: 10px;
}

.logo-menu-open {
  display: block;
  width: 100%;
  height: 30px;
  margin-left: 10px;
}
.logo-menu img {
  //height: 70px;
  width: 70%;
  height: 30px;
}
.main-menu-content {
  background-color: var(--ion-color-primary);
  --background: var(--ion-color-primary);

  div.user-data {
      //background: #fff;
      
      width: 100%;
    
      margin-top: 15px;
      vertical-align: middle;
      margin-bottom: auto;
      margin-bottom: 15px;
      .avatar-close {
          text-align: center;
          margin: 0 auto;
          width: 25px;
          height: 25px;
          transition: width 0.5s, height 0.5s;
      }
      .avatar-open {
          text-align: center;
          margin: 0 auto;
          width: 75px;
          height: 75px;
      }
      p {
          width: 100%;
          text-align: center;
      }
      div.name {
          font-size: 16px;
          font-weight: bold;
      }
      div.email {
          font-size: 14px;
          font-weight: 500;
         
      }
      p.close {
          font-size: 5px;
          color: #fff;
          transition: font-size 0.5s, color 0.5s;
      }
      p.open {
          font-size: 14px;
         // color: #000;
      }
  }
.minimized div.user-data{
  display: none;
}
  .item {
      cursor: pointer;
      //border-bottom: 1px solid #ccc;
      .icon {
          font-size: 24px;
          margin-right: 0;
          transition: margin-right 0.5s;
          color: white;
      }
      .icon-open {
          margin-right: 5px;
      }
      .label {
          font-size: 14px;
          color: #fff;
          transition: color 0.5s;
      }
      .label-open {
         // color: #000;
      }
  }

  .item:hover .label {
      //color: $hover-color;
  }

  


}

.main-menu-opened{
  div.user-data, ion-menu  div.user-data {
    display: block;
  }
   
}

.main-menu ion-list.list-md, .main-menu ion-list.list-md .item{
  background-color: var(--ion-color-primary);
  --background: var(--ion-color-primary);
  background: var(--ion-color-primary);
}
.main-menu ion-list.list-md .item.active, 
.main-menu ion-list.list-md .item.active .icon,
.main-menu ion-list.list-md .item.active .label  {
  background-color: white;
  --background: white;
  color: var(--ion-color-primary);
}
.main-menu .header-md:after, ion-menu .header-md:after{
  background-image: none;
}
.mat-expansion-indicator
{
    //margin-right: 16px;
    //display: block;
    padding-right: 16px !important;
    padding-left: 16px !important;
    // margin-left: 16px !important;
    // margin-right: 16px !important;
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  //  box-shadow: none;// 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.display-flex { display: flex; }

.flex-1 { flex: 1; }

.flex-2 { flex: 2; }

.flex-3 { flex: 3; }

.flex-4 { flex: 4; }

.flex-5 { flex: 5; }

.flex-6 { flex: 6; }

[class*="flex-"] {
  padding-left: 10px;
  padding-right: 10px;
}

[class*="flex-"]:first-child {
  padding-left: 0;
}

[class*="flex-"]:nth-last-child(2) {
  padding-right: 0;
}


.readonly .mat-form-field-underline{
  background-color: white;
}
.readonly .mat-form-field.ng-untouched .mat-form-field-underline {
background-color:  white;
}

.mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after,
.mat-form-field-disabled span.mat-select-value-text,
.mat-form-field-disabled span.mat-checkbox-label
{
  color: rgb(62, 62, 62);
}



$regular-font: 'Nunito Regular';
$bold-font: 'Montserrat Bold';
$black-font: 'Montserrat Black';

ion-list-header {
  font-family: $regular-font;
}
ion-list-header {
  font-family: $regular-font;
}
.mat-typography{
  font-family: $regular-font;
}
ion-note {
  font-family: $regular-font;
}

ion-label {
  font-family: $regular-font !important;
}

ion-title {
  font-family: $regular-font !important;
}

ion-button {
  font-family: $regular-font !important;
}

input {
  font-family: $regular-font !important;
}

formly-validation-message {
  font-family: $regular-font !important;
}

.mat-form-field-infix {
  font-family: $regular-font !important;
}

ion-card-content {
  font-family: $regular-font !important;
}
:root {
 --ion-font-family: $regular-font;
}

.mat-icon{
  color: var(--ion-color-primary);
}
.mat-menu-content .mat-icon{
  color: #8e8e8e;
}
button:focus{
  outline: none;
}
.table-footer-ap{
  width: 100%;
}
tbody .UpdatedDate{
  color: #8e8e8e;
}
th.default-prop{
  width: 100%;
}
td .go-to-details{
  left: 0;
  max-width: 300px;
  //display: inline;
  cursor: pointer;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration-line: underline;
//  font-weight: bold;
  color:var(--ion-color-primary);
}

ul li {
  font-family: 'Montserrat Regular';
}

.evidence-content .num-observaciones{
  font-size: 26px;;
}
/*.alert-button.primary{
 background-color: var(--ion-color-primary);
--border-radius: 4px;
--padding-top: 0;
--padding-bottom: 0;
--padding-start: 1.1em;
--padding-end: 1.1em;
--transition: box-shadow 280ms cubic-bezier(0.4,0,0.2,1),background-color 15ms linear,color 15ms linear;
--background: var(--ion-color-primary,#3880ff);
--color: var(--ion-color-primary-contrast,#fff);
--background-activated: transparent;
--background-hover: var(--ion-color-primary-contrast,#fff);
--background-focused: var(--ion-color-primary-contrast,#fff);
--background-activated-opacity: 0;
--background-focused-opacity: 0.24;
--background-hover-opacity: 0.08;
--box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2),0 2px 2px 0 rgba(0,0,0,0.14),0 1px 5px 0 rgba(0,0,0,0.12);
-webkit-tap-highlight-color: transparent;

border-radius: var(--border-radius);
-webkit-font-smoothing: antialiased;
margin: 0px;
padding-top: var(--padding-top);
padding-bottom: var(--padding-bottom);

transition: var(--transition);
border-width: var(--border-width);
border-style: var(--border-style);
border-color: var(--border-color);
outline: none;

box-shadow: var(--box-shadow);
contain: layout style;
cursor: pointer;
opacity: var(--opacity);

box-sizing: border-box;
appearance: none;
padding-left: unset;
padding-right: unset;
padding-inline-start: var(--padding-start);
padding-inline-end: var(--padding-end);
background: var(--ion-color-primary);
color: var(--ion-color-primary-contrast);
}*/
// fix IOS
ion-toolbar ion-buttons ion-menu-button,ion-toolbar ion-buttons ion-back-button, ion-toolbar ion-buttons ion-button:not(ion-modal ion-header ion-toolbar ion-buttons ion-button)  {
  color: white !important;
}
ion-modal ion-footer ion-toolbar ion-buttons ion-button{
  color: var(--ion-color-primary) !important;
  --color: var(--ion-color-primary) !important;
}

ion-header ion-toolbar {

  --background: var(--ion-color-primary);
  color: white;

}

.tabs-inner ion-header ion-toolbar, 
ion-modal ion-header ion-toolbar, 
ion-modal ion-header ion-toolbar ion-buttons ion-button,
ion-modal ion-header ion-toolbar ion-buttons ion-button span{
  --background: white;
  border-radius: 0px 0px 0px 0px !important;
  color: var(--ion-color-primary);
 
}
.main-menu ion-header ion-toolbar, ion-menu ion-header ion-toolbar{
  --background: var(--ion-color-primary);
  border-radius: 0px 0px 0px 0px !important;
}

.tabs-inner ion-toolbar ion-buttons ion-menu-button,  .tabs-inner ion-toolbar ion-buttons ion-button  {
  color:var(--ion-color-primary) !important;
}


ion-modal .header-collapse-condense, ion-modal .header-md.ion-no-border:after{
  display: block;
}
// .title-content-wrapper:not(.tabs-inner  .title-content-wrapper){
//   background-color: var(--ion-color-primary);
// }
// ion-content.existFilter .title-content-wrapper
// {
//   background-color: white !important;
// }
// .title-content:not(.tabs-inner  .title-content){
//   background-color:white;
//   border-radius: 35px 0px 0px 0px;
//   padding: 15px;
//   padding-left: 25px;
//   text-align: left;
//   font-size: 23px;
//   color: #404A58;
  
// }
generic-list ion-header:after, 
generic-list .header-md:after{
  background: none;
  background-image: none !important;
}
ion-modal generic-list ion-header:after, 
ion-modal generic-list .header-md:after{
  background: initial;
  background-image: initial;
}
sfs-table td{
  white-space: nowrap;
}
sfs-table th{
  white-space: pre;
}

ion-button.primary{
  --background: var(--ion-color-primary) !important;
  --border-radius: 50px;
  
}
 ion-fab-button.sfs-button.primary{
  --background: var(--ion-color-primary) !important;
 }

 //.mat-form-field-appearance-legacy .mat-form-field-label
 /*::ng-deep .mat-form-field-required-marker { 
//.mat-form-field-appearance-legacy  label.mat-form-field-label  span.mat-form-field-required-marker{
  font-size: 100%;
  font-weight: bold;
  color: --var(--ion-color-danger) !important;
}*/

label span.mat-form-field-required-marker{
  color: red ;
}

.crop{
  text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
}