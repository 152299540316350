@font-face {
    font-family: 'Nunito Regular';
    src: url('Nunito/Nunito-Regular.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Nunito Regular';
    src: url('Nunito/Nunito-Bold.ttf') format('truetype');
    font-weight: bold;
    font-display: swap;
}